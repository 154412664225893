<template>
  <!-- 申請後メッセージ -->
  <div>
    <b-card no-body class="py-4">
      <b-card-body>
        <div class="text-center">
          <b-icon
            icon="envelope"
            scale="3"
            variant="cmain"
            class="mb-5"
          ></b-icon>
        </div>
        <b-card-text class="lead text-center text-cmain">
          仮申請ありがとうございます。<br />メールをご確認ください。
        </b-card-text>
        <b-card-text>
          <b class="text-caccent">まだMYPLR口座開設申請は完了していません。</b>
          ご入力頂いたメールアドレスに、申請完了のためのメールをお送りいたします。送信されたメールに記載されているURLを24時間以内にクリックして申請を完了させてください。
        </b-card-text>
        <b-card-text class="text-csub">
          メールが届かない場合は、ご入力頂いたメールアドレスが間違っている、迷惑メールフォルダに入っているなどが考えられます。一度ご確認いただき、間違っている場合は、お手数ですが再度申請をお願いします。その他の場合は、管理者にご連絡ください。<br />
          ご入力頂いたメールアドレス: {{ email }}
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ['email'],
}
</script>
